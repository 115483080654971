////////////////////////////////////
// FormsV2


.simple-column-impl() {
    display: flex;
    flex-direction: column;

    & > * {
        width: 100%;
        margin-bottom: 0.25em;
    }
}

.grid-display() {
    display: grid;
    column-gap: 1em;
    row-gap: 1em;
}

.forms-v2--root {
    .simple-column-impl();
}

.forms-v2--row {
    .grid-display();
}

.forms-v2--row--column {
    .simple-column-impl();
}

.forms-v2--flow {
    .grid-display();
}

.forms-v2--simple-field {

}

.forms-v2--checkbox {
    & > input {
        float: left;
        display: inline-block;
        margin-right: 15px;
        vertical-align: baseline;
        margin-top: 0.4rem;
    }

    clear: both;
}


////////////////////////////////////////////////
// Seating Chart and other SDK-Custom Elements


tm-viewport {
  overflow: hidden;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;

  &[chrome="top-left"]::part(chrome),
  &.chrome--top-left::part(chrome),
  {
    border-right: 1px solid var(--secondary-button-border-color);
    border-bottom: 1px solid var(--secondary-button-border-color);
    border-bottom-right-radius: 4px;
  }

  &[chrome="top-right"]::part(chrome),
  &.chrome--top-right::part(chrome),
  {
    border-left: 1px solid var(--secondary-button-border-color);
    border-bottom: 1px solid var(--secondary-button-border-color);
    border-bottom-left-radius: 4px;
  }

  &[chrome="bottom-left"]::part(chrome),
  &.chrome--bottom-left::part(chrome)
  {
    border-right: 1px solid var(--secondary-button-border-color);
    border-top: 1px solid var(--secondary-button-border-color);
    border-top-right-radius: 4px;
  }

  &[chrome="bottom-right"]::part(chrome),
  &.chrome--bottom-right::part(chrome)
  {
    border-left: 1px solid var(--secondary-button-border-color);
    border-top: 1px solid var(--secondary-button-border-color);
    border-top-left-radius: 4px;
  }
}

tm-viewport::part(chrome) {
  padding-top: 9px;
  padding-bottom: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--secondary-button-background-color);
  color: var(--secondary-button-text-color);
  font-size: 30px;
  user-select: none;
}

tm-viewport::part(zoom-in), tm-viewport::part(zoom-out) {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  font-weight: 700;
  margin-top: -8px;
}

tm-viewport::part(zoom-out) {
}

tm-viewport::part(zoom-in) {
}


tm-seatselect::part(seat) {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--alert-warning-color);
  border-radius: 0.25rem;
  padding: 0.75rem 1.25rem;
  color: var(--alert-warning-color);
  margin-bottom: 0.5rem;
  gap: 0.5rem;
}

tm-seatselect::part(seat-title) {
  width: 100%;
  display: flex;
}

tm-seatselect::part(seat-name) {
  flex-grow: 1;
  flex-shrink: 1;
}

tm-seatselect::part(seat-close) {
  background: transparent;
  border: 0;
  font-size: 1.25rem;
  color: var(--alert-warning-color);
}

tm-seatselect::part(discounts) {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;

    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    text-transform: none;
    margin: 0;
    font-family: inherit;
    box-sizing: border-box;

    background: var(--content-background-color);
    color: var(--context-text-color);
    border-color: var(--content-border-color);
}

tm-countdown {
  display: inline;
}

tm-article-action::part(button),
tm-gift-card-action::part(button),
tm-rebate-card-action::part(button)
{
    background-color: var(--primary-button-background-color);
    color: var(--primary-button-text-color);
    border-color: var(--primary-button-border-color);
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
