// variables

/*
 * @breakpoint-xs: 576px;
 * @breakpoint-sm: 768px;
 * @breakpoint-md: 992px;
 * @breakpoint-lg: 1200px;
 * 
 * @header-background-color: white;
 * @header-text-color: #666;
 * 
 * @body-background-color: #eee;
 * @body-background-image: none;
 * @body-text-color: #444;
 * 
 * @footer-background-color: #111;
 * @footer-text-color: white;
 * 
 * @content-header-color: #f9f9f9;
 * @content-background-color: white;
 * @content-border-color: #dadada;
 * @content-text-color: #333;
 * 
 * @primary-button-background-color: #ee7c26;
 * @primary-button-text-color: white;
 * @primary-button-border-color: #ee7c26;
 * @primary-button-background-color-hover: #f58d3e;
 * @primary-button-text-color-hover: white;
 * 
 * @secondary-button-background-color: #f9f9f9;
 * @secondary-button-text-color: #666;
 * @secondary-button-border-color: #dadada;
 * @secondary-button-background-color-hover: white;
 * @secondary-button-text-color-hover: #666;
 * 
 * @link-color: #0fb6e6;
 */

@breakpoint-xs: var(--breakpoint-xs);
@breakpoint-sm: var(--breakpoint-sm);
@breakpoint-md: var(--breakpoint-md);
@breakpoint-lg: var(--breakpoint-lg);

@header-background-color: var(--header-background-color);
@header-text-color: var(--header-text-color);

@body-background-color: var(--body-background-color);
@body-background-image: var(--body-background-image);
@body-text-color: var(--body-text-color);

@footer-background-color: var(--footer-background-color);
@footer-text-color: var(--footer-text-color);

@content-header-color: var(--content-header-color);
@content-background-color: var(--content-background-color);
@content-border-color: var(--content-border-color);
@content-text-color: var(--content-text-color);

@primary-button-background-color: var(--primary-button-background-color);
@primary-button-text-color: var(--primary-button-text-color);
@primary-button-border-color: var(--primary-button-border-color);
@primary-button-background-color-hover: var(--primary-button-background-color-hover);
@primary-button-text-color-hover: var(--primary-button-text-color-hover);

@secondary-button-background-color: var(--secondary-button-background-color);
@secondary-button-text-color: var(--secondary-button-text-color);
@secondary-button-border-color: var(--secondary-button-border-color);
@secondary-button-background-color-hover: var(--secondary-button-background-color-hover);
@secondary-button-text-color-hover: var(--secondary-button-text-color-hover);

@link-color: var(--link-color);

@import "./style.css.less";
