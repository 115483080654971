// template defaults

body {
    color: @body-text-color;
}

a {
    color: @link-color;
}

body:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -1;
    background: @body-background-image;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

body.iframe {
    &, main.container, #flash_container {
        background: transparent !important;
        padding: 0 !important;
        margin: 0 !important;
        max-width: unset !important;
    }
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    &.btn-primary {
        background-color: var(--primary-button-background-color-hover);
        color: var(--primary-button-text-color-hover) !important;
        border-color: var(--primary-button-border-color);
    };

    &.btn-secondary {
        background-color: var(--secondary-button-background-color-hover);
        color: var(--secondary-button-text-color-hover) !important;
        border-color: var(--secondary-button-border-color);
    };
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;

    & > main {
        flex-grow: 1;
        flex-shrink: 1;
    }
}

.tmpl-header {
    background: @header-background-color;
    color: @header-text-color;
}

.navbar {
    
    .dropdown-menu {
        background: @header-background-color;
        color: @header-text-color;
        min-width: 200px;    
        max-width: 90vw;
    }

    .dropdown-item {
        color: @header-text-color;
    }

    .nav-link {
        color: @header-text-color !important;
    }

    .dropdown-item.active,
    .dropdown-item:active,
    .dropdown-item:hover {
        color: @primary-button-text-color;
        background: @primary-button-background-color-hover;
    }
}

.logo-header {
    max-height: 60px;
    height:auto;
}

.tmpl-background {
    background: @body-background-color;
}

.tmpl-footer {
    background: @footer-background-color;
    color: white;
}

.tmpl-content {
    background: @content-background-color;
    color: @content-text-color;
}

.tmpl-btn-primary {
    background: white;
}

.btn-primary,
.btn-primary:visited,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: @primary-button-background-color;
    color: @primary-button-text-color;
    border-color: @primary-button-border-color;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    background-color: @primary-button-background-color-hover;
    color: @primary-button-text-color-hover;
    border-color: @primary-button-border-color;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: @primary-button-background-color;
    color: @primary-button-text-color;
    border-color: @primary-button-border-color;
    opacity: 0.3;
}

.btn-secondary,
.btn-secondary:visited,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: @secondary-button-background-color;
    color: @secondary-button-text-color;
    border-color: @secondary-button-border-color;
}

.btn-secondary:hover:not(:disabled),
.btn-secondary.active:not(:disabled),
.btn-secondary:active:not(:disabled),
.btn-secondary:focus:not(:disabled),
.show>.btn-secondary.dropdown-toggle {
    background-color: @secondary-button-background-color-hover !important;
    color: @secondary-button-text-color-hover;
    border-color: @secondary-button-border-color;
}

//datatable
table {
    border:1px solid @content-border-color;
    border-radius:3px;
}

table td, .container table thead th {
    border:none;
}

//pagination
.pagination {
    float:right;
}

.paginate_button a {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    text-decoration:none !important;
    background-color: @secondary-button-background-color;
    color: @secondary-button-text-color;
    border-color: @secondary-button-border-color;
}

.paginate_button.active a {
    background-color: @primary-button-background-color;
    color: @primary-button-text-color;
    border-color: @primary-button-border-color;
}

.paginate_button.previous a {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}

.paginate_button.next a {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

.dropdown-item.icon,
.nav-item.icon {
    i {
        width: 2em;
    }
}

body>main.container {
    padding-top: 4.6em;
    padding-bottom: 4.6em;
}

@media only screen and (min-width: 576px) {
    main.container {
        padding-left: 0;
        padding-right: 0;
    }
}

// custom
.login-dropdown {
    width: 560px;
}

.bold {
    font-weight: 500;
}

a,
.completed-menu .nav-rounded .nav-link,
.completed-menu .nav-rounded .nav-link.active {
    color: @link-color;
    font-weight: 500;
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.show>.btn.dropdown-toggle:focus,
.btn:focus {
    box-shadow: none;
}

.btn {
    min-width: 44px;
}

iframe {
    background: #f1f1f1;
    border: 1px solid rgba(0,0,0,.025);
    border-radius: 3px;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}

.allow-google-maps-container {
    width: 100%;
    height: 300px;
    background: #EEE;
    position: relative;
    border-radius: 3px;
    border: 1px solid #E8E8E8;
}

.navbar .navbar-nav .dropdown-toggle:after {
    content: none;
}

.navbar .navbar-nav .dropdown-menu {
    position: absolute;
    margin-top: 0.5rem;
    z-index: -1;
}

.dropdown-header {
    font-size: 1rem;
    margin-bottom: 0.5em;
    white-space: normal;
}

.tm-hint {
    color: @primary-button-background-color-hover;
    font-size: 125%;
    vertical-align: middle;
    margin-right: 0.25em;
}

.card {
    overflow: hidden;
}

.card-img-top {
    height: 10em;
    background-size: cover;
    background-position: center center;
    background-color: #f1f1f1;
}

.card-meta {
    font-weight: 500;
}

.card-meta-tag {
    display: inline-block;
    padding-right: 1em;
}

.card-meta-tag.keyword {
    display: inline-block;
    background: @body-background-color;
    padding: 0.2em 1em;
    border-radius: 1em;
}

.card-date {
    display: inline-block;
    position: absolute;
    width: 3.25em;
    height: 3.25em;
    overflow: hidden;
    border: 1px solid #eee;
    border-radius: 100%;
    text-align: center;
    margin-top: -3px;
    margin-left: -3px;
    color: @primary-button-background-color;
}

.card-date .bundle-box {
    font-size: 1.5rem;
    padding-top: 0.5em;
}

.card-day {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1rem;
    padding-top: 0.5em;
}

.card-month {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.7rem;
}

h5.card-title {
    height: 2.6em;
    margin-left: 3.6em;
    margin-right: 3.6em;
    overflow: hidden;
    font-size: 1.0675rem;
}

.card-price {
    right: 1.1em;
    position: absolute;
    top: 1.3em;
    font-weight: 500;
    color: @primary-button-background-color;
}

.card-group .card:hover {
    box-shadow: 0 1px 5px rgba(124, 124, 124, 0.1);
}

.card-group .card:hover>.card-img-top {
    opacity: 0.95;
}

.card-state {
    background: #ffffff57;
    font-weight: 500;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.card-state>div {
    background: #ffffff;
    padding: 0.5em 20em;
    border: 1px solid #00000021;
    transform: rotate(342deg);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: var(--primary-button-background-color);
    color: var(--primary-button-text-color) !important;
}

body>main>page {
    padding-top: 3em;
}

footer {
    background: @footer-background-color;
    color: @footer-text-color;
    font-weight: 300;
}

footer a {
    color: @footer-text-color;
    font-weight: 300;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tm-icon {
    color: #aaa;
    min-width: 20px;
    text-align: center;
}

.delete-tm-icon>a {
    text-decoration: none;
}

.delete-tm-icon>a:not(:hover) {
    color: #aaa;
}

.bd-1 {
    border-bottom: 1px solid #dfdfdf;
}

.line-header {
    position: relative;
    text-align: center;
}

.line-header:before {
    content: '';
    display: block;
    border-top: solid 1px rgba(0, 0, 0, 0.05);
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    z-index: 0;
}

.line-header span {
    background: @body-background-color;
    padding: 0 10px;
    position: relative;
}

.tm-footer-logo {
    height: 22px;
}

.accordion:after {
    content: "\f068";
    float: right;
    font-family: 'Font Awesome 5 Free';
    vertical-align: middle;
    font-weight: 600;
}

.accordion.collapsed:after {
    content: "\f078";
}
.accordions > .card-header:not(:first-child) {
    border-top: 1px solid rgba(0,0,0,.125);
}
.card-header.accordion.collapsed {
    border-bottom-color: transparent;
}
.accordions > .card:not(:first-child) {
    margin-top: 0.25rem;
}
.table-body > .row:hover {
    background: #f7f7f7;
}

.accordion + div {
    border-left: 15px solid #DDD;
}

.table-header {
    padding: 0.5em 15px;
    background: @content-header-color;
    border-bottom: 1px solid @content-border-color;
}

.table-body {
    padding: 0 15px;
}

.table-body>.row {
    padding: 0.5em 0;
    position: relative;
}

.row.sold-out {
    line-height: 30px;
}

.row.sold-out:after {
    content: "";
    border-top: 1px solid #444;
    position: absolute;
    top: 23px;
    left: 0.75em;
    right: 0.75em;
}

.table-footer {
    padding: 0.5em 15px;
}

.no-wrap {
    white-space: nowrap;
}

#error_explanation h2 {
    display: none;
}

#error_explanation ul {
    margin: 0;
}

.cart-img {
    width: 2.75em;
    height: 2.75em;
    background-size: cover;
    background-position: center center;
}

.cart_item .gutscheincode {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cart-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dropdown-menu {
    max-height: 65vh;
    overflow-y: auto;
    overflow-x: hidden !important;
}

.numbered-list li {
    margin-bottom: 1em;
    padding-left: 1em;
}

#cart_container.new>a,
#cart_container.new>.dropdown-menu,
.update-flash {
    -webkit-animation: update 2s;
    /* Safari, Chrome and Opera > 12.1 */
    animation: update 2s;
}

.primary-text {
    color: @primary-button-background-color;
    font-weight: 500;
}

strong.primary-text {
    color: @primary-button-background-color;
    font-weight: bolder;
}

.lang-div {
    display: inline-block;
}

//form fields
#additional-fields .group > div {
    padding-top: 8px;
    padding-bottom: 8px;
}

//spinner
.spinner-container, .viewport_spinner_container {
    position: fixed;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
}

.viewport_spinner_container {
    background: @body-background-color !important;
    opacity: 0.9 !important;
}

.spinner,
.spinner:after,
.viewport_spinner,
.viewport_spinner:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.spinner, .viewport_spinner {
    font-size: 10px;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    right: auto !important;
    bottom: auto !important;
    margin-top: -10em;
    margin-left: -5em;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
.viewport_spinner {
    margin-top: -5em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid @primary-button-background-color;
}

.control-label {
    display: inline-block;
    margin-bottom: 0.25rem;
    margin-top: 0.75rem;
    font-weight: 500;
    font-size: 90%;
    text-transform: uppercase;
}

//checkout
#checkout_form .checkout-gu-code {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nav-rounded {
    position: relative;
}

.nav-rounded:before {
    content: '';
    height: 2px;
    position: absolute;
    top: 50%;
    left: 12%;
    right: 12%;
    z-index: -1;
    background-color: #dadada;
}

.nav-rounded .nav-link {
    width: 3em;
    height: 3em;
    line-height: 3em;
    background: white;
    border: 1px solid #dadada;
    font-size: 1.25em;
    padding: 0;
    margin: 0 auto;
    border-radius: 100%;
}

.completed-menu .nav-rounded .nav-item .nav-link.active {
    border: 1px solid #dadada;
}

.nav-rounded .nav-link.active,
.completed-menu .nav-rounded .nav-item:last-child .nav-link {
    border: 1px solid @primary-button-background-color;
    color: @primary-button-background-color;
}

.nav-rounded .nav-link.complete {
    border: 1px solid @primary-button-background-color;
    background: @primary-button-background-color;
    color: #fff;
}

/* Checkboxes, Radios & Switches */
.custom-control.material-checkbox {
    --color: #26a69a;
    cursor: pointer;
}

.custom-control.material-checkbox .material-control-input {
    display: none;
}

.custom-control.material-checkbox .material-control-input:checked~.material-control-indicator,
.custom-control.material-checkbox > .material-control-indicator.checked,
{
    border-color: var(--color);
    -webkit-transform: rotateZ(45deg) translate(1px, -5px);
    transform: rotateZ(45deg) translate(1px, -5px);
    width: 10px;
    border-top: 0px solid #fff;
    border-left: 0px solid #fff;
}

.custom-control.material-checkbox .material-control-indicator {
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #aaa;
    transition: 0.3s;
}

.custom-control .material-control-description {
    display: inline-block;
    margin-left: 0.5em;
}

.custom-control.fill-checkbox {
    --color: #26a69a;
}

.custom-control.fill-checkbox .fill-control-input {
    display: none;
}

.custom-control.fill-checkbox .fill-control-input:checked~.fill-control-indicator {
    background-color: var(--color);
    border-color: var(--color);
    background-size: 80%;
}

.custom-control.fill-checkbox .fill-control-indicator {
    border-radius: 3px;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #aaa;
    transition: 0.3s;
    background: transperent;
    background-size: 0%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-control.overflow-checkbox .overflow-control-input {
    display: none;
}

.custom-control.overflow-checkbox .overflow-control-input:checked~.overflow-control-indicator::after {
    -webkit-transform: rotateZ(45deg) scale(1);
    transform: rotateZ(45deg) scale(1);
    top: -6px;
    left: 5px;
}

.custom-control.overflow-checkbox .overflow-control-input:checked~.overflow-control-indicator::before {
    opacity: 1;
}

.custom-control.overflow-checkbox .overflow-control-indicator {
    border-radius: 3px;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #aaa;
}

.custom-control.overflow-checkbox .overflow-control-indicator::after {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    transition: 0.3s;
    -webkit-transform: rotateZ(90deg) scale(0);
    transform: rotateZ(90deg) scale(0);
    width: 10px;
    border-bottom: 4px solid #aaa;
    border-right: 4px solid #aaa;
    border-radius: 3px;
    top: -2px;
    left: 2px;
}

.custom-control.overflow-checkbox .overflow-control-indicator::before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    transition: 0.3s;
    width: 10px;
    border-right: 7px solid #fff;
    border-radius: 3px;
    -webkit-transform: rotateZ(45deg) scale(1);
    transform: rotateZ(45deg) scale(1);
    top: -4px;
    left: 5px;
    opacity: 0;
}

.custom-control.material-switch {
    --color: #26a69a;
    padding-left: 0;
}

.custom-control.material-switch .material-switch-control-input {
    display: none;
}

.custom-control.material-switch .material-switch-control-input:checked~.material-switch-control-indicator::after {
    background-color: var(--color);
    left: 17px;
}

.custom-control.material-switch .material-switch-control-indicator {
    display: inline-block;
    position: relative;
    margin: 0 10px;
    top: 4px;
    width: 32px;
    height: 16px;
    background: #ddd;
    border-radius: 16px;
    transition: 0.3s;
}

.custom-control.material-switch .material-switch-control-indicator::after {
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: 0.3s;
    top: -1px;
    left: -1px;
    background: #fdfdfd;
    box-shadow: 0 2px 10px #aaa;
}

.custom-control.ios-switch {
    --color: #4cd964;
    padding-left: 0;
}

.custom-control.ios-switch .ios-switch-control-input {
    display: none;
}

.custom-control.ios-switch .ios-switch-control-input:active~.ios-switch-control-indicator::after {
    width: 20px;
}

.custom-control.ios-switch .ios-switch-control-input:checked~.ios-switch-control-indicator {
    border: 10px solid var(--color);
}

.custom-control.ios-switch .ios-switch-control-input:checked~.ios-switch-control-indicator::after {
    top: -8px;
    left: 4px;
}

.custom-control.ios-switch .ios-switch-control-input:checked:active~.ios-switch-control-indicator::after {
    left: 0px;
}

.custom-control.ios-switch .ios-switch-control-indicator {
    display: inline-block;
    position: relative;
    margin: 0 10px;
    top: 4px;
    width: 32px;
    height: 20px;
    background: #fff;
    border-radius: 16px;
    transition: 0.3s;
    border: 2px solid #ddd;
}

.custom-control.ios-switch .ios-switch-control-indicator::after {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    transition: 0.3s;
    top: 0px;
    left: 0px;
    background: #fff;
    box-shadow: 0 0 2px #aaa, 0 2px 5px #999;
}

.custom-control.border-switch {
    --color: #4cd964;
    padding-left: 0;
}

.custom-control.border-switch .border-switch-control-input {
    display: none;
}

.custom-control.border-switch .border-switch-control-input:checked~.border-switch-control-indicator {
    border-color: var(--color);
}

.custom-control.border-switch .border-switch-control-input:checked~.border-switch-control-indicator::after {
    left: 14px;
    background-color: var(--color);
}

.custom-control.border-switch .border-switch-control-indicator {
    display: inline-block;
    position: relative;
    margin: 0 10px;
    top: 4px;
    width: 32px;
    height: 20px;
    background: #fff;
    border-radius: 16px;
    transition: 0.3s;
    border: 2px solid #ccc;
}

.custom-control.border-switch .border-switch-control-indicator::after {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition: 0.3s;
    top: 2px;
    left: 2px;
    background: #ccc;
}

.custom-control.teleport-switch {
    --color: #4cd964;
    padding-left: 0;
}

.custom-control.teleport-switch .teleport-switch-control-input {
    display: none;
}

.custom-control.teleport-switch .teleport-switch-control-input:checked~.teleport-switch-control-indicator {
    border-color: var(--color);
}

.custom-control.teleport-switch .teleport-switch-control-input:checked~.teleport-switch-control-indicator::after {
    left: -14px;
}

.custom-control.teleport-switch .teleport-switch-control-input:checked~.teleport-switch-control-indicator::before {
    right: 2px;
    background-color: var(--color);
}

.custom-control.teleport-switch .teleport-switch-control-indicator {
    display: inline-block;
    position: relative;
    margin: 0 10px;
    top: 4px;
    width: 32px;
    height: 20px;
    background: #fff;
    border-radius: 16px;
    transition: 0.3s;
    border: 2px solid #ccc;
    overflow: hidden;
}

.custom-control.teleport-switch .teleport-switch-control-indicator::after {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition: 0.3s;
    top: 2px;
    left: 2px;
    background: #ccc;
}

.custom-control.teleport-switch .teleport-switch-control-indicator::before {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition: 0.3s;
    top: 2px;
    right: -14px;
    background: #ccc;
}

.list-group:first-child .list-group-control:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.list-group::last-child .list-group-control:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.list-group.list-group-flush:last-child .list-group-control:last-child {
    border-bottom: 0;
    margin-bottom: 0;
}

.list-group.list-group-flush:first-child .list-group-control:first-child {
    border-top: 0;
}

.list-group.list-group-flush .list-group-control {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.list-group .list-group-control {
    position: relative;
    display: block;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group .list-group-control .custom-control {
    margin: 0;
    padding: 0.75rem 1.25rem;
    padding-left: 3rem;
    display: block;
}

.list-group .list-group-control .custom-control .custom-control-indicator,
.list-group .list-group-control .custom-control .material-control-indicator,
.list-group .list-group-control .custom-control .fill-control-indicator,
.list-group .list-group-control .custom-control .overflow-control-indicator {
    top: 1rem;
    left: 1.25rem;
}

.overview-price {
    width: 100px;
}

.overview-total-price {
    border-top: 1px solid #E8E8E8;
    margin-top: 0.5em;
    padding-top: 0.25em;
}

.cookie-law {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: @footer-background-color;
    border-bottom: 1px solid #000;
    color: @footer-text-color;
    padding: 15px;
    text-align: center;
    font-size: 0.9em;
    z-index:9999;
}

.cookie-law--config input + label:not(:last-child) {
    margin-right: 10px;
}

input[type=radio]+label, input[type=checkbox]+label {
    cursor: pointer;
}

.success-tick {
    color: #28a745;
    font-size: 5em;
}

.waiting-tick {
    font-size: 5em;
    color: #428bca;
}

/* Avatars */
.avatar {
    border-radius: 100%;
}
.avatar-xs {
    width: 20px;
}
.avatar-sm {
    width: 50px;
}
.avatar-md {
    width: 100px;
}
.avatar-lg {
    width: 150px;
}
.avatar-xl {
    width: 200px;
}

a:not([href]):not(.js-link):not([tabindex]):not(.btn) {
    color: #fff;
}

.float-right {
    float:right;
}

.settings-field-view {
    display: none;
}

/* Badges */
.badge {
    padding: 4px 7px !important;
    white-space: normal !important;
}

/* Alerts */
.alert-notice {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-error,
.alert-alert,
#error_explanation {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

#error_explanation {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

/* Calendar */
.title-height {
    min-height:50px;
}

.card-img-top .event-badges, .media-img .event-badges {
    position: absolute;
    top: 0.25rem;
    right: 0.5rem;
}

a.tm-list-title {
    color: #333;
}

li.media {
    border: 1px solid #E8E8E8;
    background:#FFF;
    border-radius: 3px;

}

.media-img {
    width: 80px;
    height: 80px;
    background-color: #ddd;
    background-position: center center;
    background-size: cover;
    border-radius: 3px;
    position:relative;
}

/* Aspect Ratios */
.ratio-16-9 {
    width: 100%;
    padding-top: 56.25%;
    height: 0px;
}

.ratio-1-1 {
    width: 100%;
    padding-top: 100%;
    height: 0px;
}

//animations

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes update {
    from {
        background: #fff5ce;
    }

    to {
        background: #fff;
    }
}

@keyframes update {
    from {
        background: #fff5ce;
    }

    to {
        background: #fff;
    }
}

// xl

@media only screen and (min-width: 992px) and (min-height: 576px) {
    footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
    }
}

// lg
@media (max-width: 992px) {}

@media (min-width: 768px) {}

// md
@media (max-width: 768px) {
    .no-height-mobile {
        height:auto;
    }
}

@media (min-width: 576px) {
    .tmpl-header {
        max-height: 74px;
    }

    .flex-column-md {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
}

// sm
@media (max-width: 576px) {
    .navbar > .container {
        flex-direction: column;
    }
    .navbar>.container {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
        margin-top: 0.5em;
    }
    .logo-header {
        max-width: 70vw;
        margin-bottom: 0.5rem;
    }

    .form-group {
        margin-bottom: 0.5rem;
    }
    body > main.container {
        padding-top: 8.3em;
    }
}

@media (min-width: @breakpoint-xs) {}

// xs
@media (max-width: @breakpoint-xs) {
    body>main.container {
        padding: 6.4em 1em;
        padding-bottom: 2em;
    }

    .nav-rounded .nav-link {
        width: 2.5em;
        height: 2.5em;
        line-height: 2.5em;
    }
}

.hidden {
    display:none;
}


.header-cart-totals {
    tr {
        & > td:first-child {
            text-align: right;
        }
        & > td:last-child { 
            & > strong {
                display: block;
                text-align: right;
            }
        }
    }
}

body .btn-group > .btn-secondary.active {
    background-color: var(--primary-button-background-color) !important;
    color: var(--primary-button-text-color) !important;
    border-color: var(--primary-button-border-color) !important;

    &:hover {
        background-color: var(--primary-button-background-color-hover) !important;
        color: var(--primary-button-text-color-hover) !important;
        border-color: var(--primary-button-border-color) !important;
    }
}

.coupon-display {
    & > form {
        padding: 0.5em;
    }
}

.seating-chart-popup {
    background-color: var(--content-background-color);
    border: 1px solid var(--content-border-color);
    padding: .25rem .5rem;
    margin-top: 15px;
    margin-left: 10px;
}


.seating-chart-ui {
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        display: flex !important;
        flex-direction: column;
        row-gap: 0;

        & > .seating-chart-top {
            order: 1;
            flex-direction: column;
            justify-content: start;

            & .ticketmachine-actions {
                display: flex;
                gap: 0.5em;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                text-align: left !important;
                margin-bottom: 0 !important;
                min-height: 30px !important;
                &::after {
                    content: "";
                    flex: auto;
                }
            }
        }

        & > .seating-chart-aside {
            display: none;
        }

        & > .seating-chart-info {
            order: 2;
            & > tm-seatselect {
                display: none;
            }
        }

        & > .seating-chart-viewer {
            order: 3;
            & > tm-seatselect {
                padding-top: 0.5em;
                display: block !important;
            }

            & > .seating-chart-buttons {
                display: block !important;
            }
        }
    }
    &.landscape {
        display: grid;
        grid-template-areas: "top top" "viewer viewer" "left right";
        grid-template-columns: 1fr 1fr;
        column-gap: 1em;
        row-gap: 0.5em;

        & > .seating-chart-top {
            grid-area: top;
            display: flex;
            justify-content: space-between;
        }

        & > .seating-chart-viewer {
            grid-area: viewer;
            & > .seating-chart-buttons {
                display: none;
            }

            & > tm-seatselect {
                display: none;
            }
        }

        & > .seating-chart-info {
            grid-area: left;
            & > tm-seatselect {
                display: none;
            }
        }

        & > .seating-chart-aside {
            grid-area: right;
        }

    }

    &.portrait {
        display: grid;
        grid-template-areas: "top top" "left right";
        grid-template-columns: 1fr 1fr;
        column-gap: 1em;
        row-gap: 0.5em;
        & > .seating-chart-top {
            grid-area: top;
            display: flex;
            justify-content: space-between;
        }

        & > .seating-chart-aside {
            display: none;
        }

        & > .seating-chart-info {
            grid-area: left;
            display: flex;
            flex-direction: column;

            & > tm-seatselect {
                order: 1;
            }

            & > .seating-chart-data {
                order: 2;
            }

            & > .seating-chart-gmaps {
                order: 3;
            }

            & > .seating-chart-buttons {
                display: none;
            }
        }

        & > .seating-chart-viewer {
            grid-area: right;
            & > tm-seatselect {
                display: none;
            }
        }
    }
}

.event-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 330px);
    justify-content: center;
    gap: 1em;
}

.event-pagination {
  display: flex;
  flex-direction: row;
}

.event-pagination .divider {
  flex: 1 1;
}

.event-operations {
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
      & > .btn-secondary {
          order: 2;
      }
      & > tmws-event-sell {
          order: 1;
      }
      flex-direction: column;
      gap: 0.5em;
  }

}

.popover-wrapper {
    background-color: var(--content-background-color) !important;
    border: 1px solid var(--content-border-color) !important;
    border-radius: 4px !important;

    @pad-block: 10px;
    @pad-inline: 10px;

    & > .popover-content {
        background-color: var(--content-background-color) !important;
        color: var(--content-text-color) !important;
        padding: @pad-block @pad-inline;

        & > ul {
            margin: 0;
        }
    }

    & > h3 {
        background-color: var(--hl-content-background-color) !important;
        color: var(--content-text-color) !important;
        padding: @pad-block @pad-inline;
        font-size: 1.3em;
        margin: 0;
    }

}

.svelte-arrow {
    color: var(--content-border-color) !important;
}
