
body {
  background-color: var(--body-background-color);
}

.card, .form-control, .form-control:focus {
  background: var(--content-background-color);
  color: var(--content-text-color);
  border-color: var(--content-border-color);
}

.tm-list-title {
  color: var(--content-text-color);
}

.media {
  background: var(--content-background-color) !important;
  color: var(--content-text-color);
  border-color: var(--content-border-color) !important;
}


.card-header {
    border-color: var(--content-border-color) !important;
    background-color: var(--content-header-color) !important;
}

.card-date {
  border-color: var(--content-border-color);
}
.card-img-top {
  background-color: var(--hl-content-background-color) !important;
};

.card-meta-tag.keyword {
    text-decoration: none !important;
}

body .bg-light {
  background-color: var(--hl-content-background-color) !important;
}

#checkout_nav .change-step {
  background-color: var(--hl-content-background-color);
}

.tm-icon {
  color: var(--body-icon-color);
}

.card .table-body .row:hover {
  background: var(--content-hover-color);
}

.allow-google-maps-container {
  background-color: var(--content-background-color) !important;
  border-color: var(--content-border-color) !important;
};
.allow-google-maps {
  color: var(--primary-button-text-color);
}
.allow-google-maps:hover {
  color: var(--primary-button-text-color-hover);
};

.input-group-text {
  background-color: var(--content-background-color);
  color: var(--hl-content-text-color);
  border-color: var(--content-border-color);
}

.btn-primary, 
.btn-primary:visited,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: var(--primary-button-text-color) !important;
}

.theme-alert(@name) {
  .alert-@{name} {
    color: var(~"--alert-@{name}-color");
    background-color: var(~"--alert-@{name}-background-color");
    border-color: var(~"--alert-@{name}-border-color");
  }
}
.theme-alert(warning);
.theme-alert(error);
.theme-alert(success);
.theme-alert(info);

.with-image, .media-img {
  .event-image {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
  }

  position: relative;
}

.row.full-height {
    flex-grow: 1;
    flex-shrink: 1;

    .col-12:first-child:last-child {
        display: flex;
        flex-direction: column;
    }
}

.row-wise, main.container {
    display: flex;
    flex-direction: column;

    & > div {
        width: 100%;

        &.fill {
            flex-grow: 1;
            flex-shrink: 1;
        }
    }
}

tm-viewport {
    display: block;
    border: 1px solid var(--secondary-button-border-color);
}


.landscape {
    tm-viewport {
        display: block;
        height: 100%;
        min-height: 500px;
    }
}
